import Vue from 'vue'
import ElementUI, { Upload } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.less'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.component(Upload.name, Upload)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
