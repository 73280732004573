<template name="card px-[20px]">
  <div class="card-box  lg:w-[32%] w-full my-[20px]">
    <div class="card-img">
      <img :src="cardObj.imgPath" alt="image" />
    </div>
    <div class="card-content p-2 lg:p-4 2xl:p-4">
      <div class="card-title">
        <div
          :title="cardObj.title"
          class="title-text text-xl 2xl:text-xl lg:text-xl lg:font-extrabold font-normal lg:max-w-[180px] max-w-[150px] ellipsis"
        >
          {{ cardObj.title }}
        </div>
        <div
          class="title-btn px-1 py-1 ls:px-2.5 ml-3 lg:min-w-[120px] text-center"
        >
          {{randerText(cardObj)}}
        </div>
      </div>
      <div class="card-text mt-2 lg:mt-4 text-sm lg:text-ls pb-[20px]">
        {{ cardObj.text }}
      </div>
      <div class="bottom-btns pb-5">
        <!-- <div
          class="plain-btn btn flex-1 lg:h-[2.3vw] lg:leading-[3.3vw] w-[35vw] h-8 leading-8 text-sm lg:text-sm 2xl:text-1xl"
          @click="toDetail"
        >
          Tell Me More
        </div> -->
        <div
          v-if="!isClick"
          class="primary-btn btn flex-1  lg:h-[2.3vw] lg:leading-[3.3vw] w-[35vw] h-8 leading-8 text-sm sm:text-sm lg:text-lg 2xl:text-1xl"
          @click="onLaunch"
        >
          Let's Launch!
        </div>
        <div
          v-else
          class="plain-btn btn flex-1 lg:h-[2.3vw] lg:leading-[3.3vw] w-[35vw] h-8 leading-8 text-sm lg:text-sm 2xl:text-1xl"
        >
          Coming Soon
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    cardObj: {
      type: Object,
      default: () => {}
    },
    scoreList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isClick: false
    }
  },
  computed: {
    ...mapState(['isLogined'])
  },
  methods: {
    ...mapActions(['connectWallet']),

    onLaunch () {
      if (this.cardObj.isComingSoon) {
        this.isClick = true
      } else {
        if (this.isLogined) {
          this.$router.push({
            path: this.cardObj.router,
            query: this.cardObj.query || {}
          })
        } else {
          this.connectWallet(false)
        }
      }
    },
    randerText (item) {
      if (this.scoreList && this.scoreList.length) {
        const score = this.scoreList[item.sortIndex].score
        return score ? `${score} points / use` : 'Free for a limited time'
      }
      return 'Free for a limited time'
    }
  }
}
</script>
<style scoped lang="less">

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-box {
  // min-width: 300px;
  // min-height: 320px;
  //margin: 20px;
  display: flex;
  flex-direction: column;
  color: #fff;
  // font-family: Jost, Cochin, Georgia, Times, 'Times New Roman', serif;
  .card-img {
    width: 100%;
    background-color: antiquewhite;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .card-content {
    background-color: rgb(25, 25, 25);
    flex: 1;
    color: #fff;
    display: flex;
    flex-direction: column;
    .card-title {
      color: #fff;
      display: flex;
      align-items: center;
      .title-text{
        font-weight: 700;
      }
      .title-btn {
        cursor: pointer;
        background-color: rgb(48, 48, 48);
        border-radius: 5px;
        font-size: 13px;
      }
    }
    .card-text {
      // display: -webkit-box;
      // -webkit-line-clamp: 4;
      // -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgba(255,255,255,0.8);
      letter-spacing: 2px;
    }
    .bottom-btns {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      .btn {
        min-width: 120px;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        width: 45%;
        &:hover {
          opacity: 0.8;
        }
      }
      .plain-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
      }
      .primary-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        color: #000;
      }
    }
  }
}
</style>
