import axios from 'axios'
import store from './../store'

// 创建一个axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // 当发送跨域请求时携带凭证信息
  withCredentials: true,
  timeout: 30000 // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么，比如添加请求头token
    // config.headers['token'] =  token;
    const token = sessionStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status === 200) {
      // 认证失败，重新走登陆流程
      if (res.code === 401) {
        store.dispatch('userLogin')
      }
      return res
    } else {
      return Promise.reject(res)
    }
  },
  error => {
    console.log('err1' + error)
    return Promise.reject(error)
  }
)

export default service
