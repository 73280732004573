import { render, staticRenderFns } from "./AdCard.vue?vue&type=template&id=0e1503a6&scoped=true&name=card%20px-%5B20px%5D"
import script from "./AdCard.vue?vue&type=script&lang=js"
export * from "./AdCard.vue?vue&type=script&lang=js"
import style0 from "./AdCard.vue?vue&type=style&index=0&id=0e1503a6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e1503a6",
  null
  
)

export default component.exports