<template>
  <footer class="footer bg-black text-gray-300 py-16 lg:w-4/5 lg:mx-auto">
    <div class="content container mx-auto  max-w-3/4 px-4 flex flex-col lg:flex-row justify-between ">
      <div class="flex flex-col items-center lg:items-start">
        <div class="logo mb-5 lg:mb-7">
          <a href="/"><img src="./../../assets/logo.svg" width="85" alt="logo"></a>
        </div>
        <div class="text-center text-sm mb-3  lg:text-left lg:mb-10">Unlock the Magic of AI with Web3!</div>
        <ul class="flex items-center space-x-8 mt-3 lg:mt-0">
          <li><a href="#"><img src="./../../assets/images/icon1.svg"></a></li>
          <li><a href="#"><img src="./../../assets/images/icon2.svg"></a></li>
          <li><a href="#"><img src="./../../assets/images/icon3.svg"></a></li>
          <li><a href="#"><img src="./../../assets/images/icon4.svg"></a></li>
        </ul>
      </div>
      <div class="mt-8 lg:mt-0 flex  px-4 lg:flex-row justify-between w-full lg:w-auto lg:px-0">
        <div class=" mb-8 lg:mb-0 lg:last:mb-0">
          <div class="title mb-3 text-white font-bold">Company</div>
          <ul>
            <li class="mb-2"><a href="#" class="text-sm hover:underline">About Us</a></li>
            <li class="mb-2"><a href="#" class="text-sm hover:underline">Contact Us</a></li>
            <li class="mb-2"><a href="#" class="text-sm hover:underline">Media Kit</a></li>
            <li><a href="#" class="text-sm hover:underline">FAQs</a></li>
          </ul>
        </div>
        <div class="lg:ml-24">
          <div class="title mb-3 text-white font-bold">Resources</div>
          <ul>
            <li class="mb-2"><a href="#" class="text-sm hover:underline">Roadmap</a></li>
            <li class="mb-2"><a href="#" class="text-sm hover:underline">Documents</a></li>
            <li class="mb-2"><a href="#" class="text-sm hover:underline">API</a></li>
            <li><a href="#" class="text-sm hover:underline">Github</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container mx-auto max-w-3/4  px-4 flex flex-col lg:flex-row justify-between my-2 lg:mt-10">
       <div class="flex flex-col items-center lg:flex-row">
            <!-- <span class="text-sm mb-3 lg:mb-0">Email Address: support@io.net</span> -->
            <a class="text-sm ml-0 hover:underline" href="#">Privacy Policy</a>
        </div>
        <!-- <div class="text-sm mt-3 text-center lg:mt-0">©️ Copyright io.net, inc. 447 Broadway, New York</div> -->
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.footer {
  border-top: 1px solid rgba(255,255,255,0.2);

}
</style>
