// src/services/StarkNetService.js
import { cairo, CallData, Contract, RpcProvider } from 'starknet'
import { ethers } from 'ethers'

import halAbi from './hal.json'
import erc20Abi from './erc20.json'

// 配置
// const privateKey = ''
// const accountAddress = ''
const halAddress = '0x03084aff0f6f31674faec8d6356064059ff0d2fdd34e6f46b301d934b5d692e4'

// const tokenAddress = '0x02f85376f25472fbef447bc805fa6ee5d705d5be369e87535db0540ad347ba67'

// 初始化提供者和账户
const provider = new RpcProvider({
  nodeUrl: 'https://rpc-sepolia.staging.nethermind.dev'
})

const hal = new Contract(halAbi, halAddress, provider)
// const token = new Contract(erc20Abi, tokenAddress, provider)

// 签名方法
const signMessageFn = async (provider, message, chainId) => {
  const timestamp = Date.now()
  const typedMessage = {
    domain: {
      name: 'Hal',
      chainId: chainId,
      version: '0.0.1'
    },
    types: {
      StarkNetDomain: [
        { name: 'name', type: 'felt' },
        { name: 'chainId', type: 'felt' },
        { name: 'version', type: 'felt' }
      ],
      Message: [
        { name: 'message', type: 'felt' },
        { name: 'timestamp', type: 'felt' }
      ]
    },
    primaryType: 'Message',
    message: {
      message,
      timestamp
    }
  }

  try {
    const signature = await provider.signMessage(typedMessage)
    return {
      signature,
      typedMessage
    }
  } catch (error) {
    console.log('Error in signMessageFn', error)
  }
}

// 获取签名
async function getSignature (provider, chainId) {
  const message = 'login'
  try {
    const { signature, typedMessage } = await signMessageFn(provider, message, chainId)
    return {
      signature,
      typedMessage
    }
  } catch (error) {
    console.log('Error in signature:', error)
  }
}

// 获取账户余额
async function getBalance (account, tokenAddress) {
  try {
    const token = new Contract(erc20Abi, tokenAddress, provider)
    const balance = await token.balance_of(account.address)
    return balance
  } catch (error) {
    console.log('Error getting balance:', error)
  }
}

// 质押代币
async function stakeTokens (account, amount, tokenAddress) {
  try {
    const multiCall = await account.execute([
      {
        contractAddress: tokenAddress,
        entrypoint: 'approve',
        calldata: CallData.compile({
          spender: halAddress,
          amount: cairo.uint256(amount)
        })
      },
      {
        contractAddress: halAddress,
        entrypoint: 'stake',
        calldata: CallData.compile({
          token: tokenAddress,
          stake_amount: cairo.uint256(amount)
        })
      }
    ])

    const stakeResult = await provider.waitForTransaction(multiCall.transaction_hash)
    return stakeResult
  } catch (error) {
    console.log('Error in stakeTokens:', error)
  }
}

// 获取质押金额
async function getStakeAmount (accountAddress, tokenAddress) {
  try {
    const stakeAmount = await hal.stake_count(tokenAddress, accountAddress)
    return stakeAmount
  } catch (error) {
    console.log('Error in getStakeAmount:', error)
  }
}

// 提取质押
async function withdrawTokens (account, amount, tokenAddress) {
  try {
    const withdrawCall = await account.execute([
      {
        contractAddress: halAddress,
        entrypoint: 'withdraw',
        calldata: CallData.compile({
          token: tokenAddress,
          withdraw_amount: cairo.uint256(amount)
        })
      }
    ])

    const withdrawResult = await provider.waitForTransaction(withdrawCall.transaction_hash)
    return withdrawResult
  } catch (error) {
    console.log('Error in withdrawTokens:', error)
  }
}

// 格式化质押金额
function formatStakeAmount (amount, decimals = 'ether') {
  return toFixedNoRounding(ethers.formatUnits(amount, decimals).toString())
}

// 还原原始质押金额
function restoreStakeAmount (formattedAmount, decimals = 'ether') {
  return ethers.parseUnits(formattedAmount, decimals)
}

// 保留4位小数，不四舍五入
function toFixedNoRounding (number) {
  const str = number.toString()
  const decimalIndex = str.indexOf('.')

  // 如果没有小数点或者需要保留的小数位数为0
  if (decimalIndex === -1 || 4 === 0) {
    return str
  }

  // 获取整数部分和小数部分
  const integerPart = str.slice(0, decimalIndex)
  const decimalPart = str.slice(decimalIndex + 1, decimalIndex + 1 + 4)

  // 拼接整数部分和截取的小数部分
  const truncatedDecimal = `${integerPart}.${decimalPart.padEnd(4, '0')}`

  return truncatedDecimal
}

export default {
  getBalance,
  stakeTokens,
  getStakeAmount,
  withdrawTokens,
  getSignature,
  formatStakeAmount,
  restoreStakeAmount
}
