import axios from './../utils/axios.js'

const api = {
  textToImage: '/api/dall/e/create',
  photoColorize: '/api/photo/colorizer',
  photoEnhance: '/api/photo/enhance',
  login: '/api/auth/web3Login',
  userInfo: '/api/auth/currentUserInfo',
  stakeInfo: '/api/auth/stakeInfo',
  scoreBillList: '/api/auth/scoreBill',
  tokenList: '/api/tokens/list',
  scoreList: '/api/tool/score/list'
}

// 文字转图片
export function textToImage (params) {
  return axios.post(api.textToImage, params)
}
export function photoColorize (params) {
  return axios.post(api.photoColorize, params)
}
export function photoEnhance (params) {
  return axios.post(api.photoEnhance, params)
}

// 登录
export function login (params) {
  return axios.post(api.login, params)
}

// 用户信息
export function userInfo (params) {
  return axios.get(api.userInfo, params)
}

// 质押信息
export function stakeInfo (params) {
  return axios.get(api.stakeInfo, params)
}

// 积分流水
export function scoreBillList (params) {
  return axios.get(`${api.scoreBillList}?curPage=${params.curPage}&limit=${params.limit}`)
}

// token列表
export function tokenList (params) {
  return axios.get(api.tokenList)
}

// 积分列表
export function scoreList (params) {
  return axios.get(api.scoreList, params)
}
