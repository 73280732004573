<template>
  <div class="home">
    <Header @scrollTo="scrollToAnchorPoint"></Header>
    <div class="flex flex-col lg:flex-row">
      <div
        class="ad-area lg:mt-8 mt-24 lg:ml-[10%]  px-0 text-white flex flex-col justify-center  text-center lg:text-left  lg:px-4"
      >
        <h2 class="title text-3xl lg:text-5xl font-extrabold">
          <div>
            HAL : Embark on a
          </div>
          <div>
            Web3 AI adventure
          </div>
        </h2>
        <div class="text my-6 text-sm  lg:text-1xl" >
          <div>
            Unlock the Magic of AI with Web3 powered by
          </div>
          <div>
            HAL's Technology
          </div>
        </div>
        <div class="flex justify-center lg:justify-start">
          <div class="get-start  rect w-[190px] lg:w-[250px] text:ls lg:text-xl  text-center" @click="scrollToAnchorPoint">Let's Get Started!</div>
        </div>
      </div>
      <div class="home-bg lg:flex-1 lg:h-[700px] h-[400px] lg:block hidden"></div>
      <div class="lg:hidden block overflow-hidden" >
        <img class="ml-[6%]" src="./../assets/images/home_bg_m.png" width="100%">
      </div>
    </div>
    <div class="content mx-[auto] my-[30px] lg:my-[60px] w-11/12 lg:w-4/5 relative">
      <div
        class="part-wrap relative bg-[url('svg/box1_m.png')] lg:bg-[url('svg/box1.png')] m-0 lg:m-5 w-full py-[10px] pl-[6px] pr-[15px]"
      >
        <div
          class="title absolute top-[-15px] lg:top-0 lg:left-9 left-0 lg:text-2xl xl:text-4xl text-xl font-bold text-white"
        >
          What Sets Us Apart
        </div>
        <div
          class="title-text absolute lg:left-9 left-0 lg:top-14 top-5 text-sm"
        >
          Make the AI experience smoother
        </div>
        <div
          class="flex flex-1 lg:ml-10 lg:pt-[130px] lg:pr-[40px] lg:pl-[30px] lg:pb-[60px]  pt-[80px] lg:flex-row flex-col"
        >
          <Part
            v-for="(item, index) in partList"
            :key="index"
            :data="item"
            class="flex-1 lg:mx-3 mx-1 mb-5 lg:mb-1"
          ></Part>
        </div>
      </div>
      <div
        class="journey  py-[40px] px-[10px] lg:pt-[80px] lg:pr-[40px] lg:pb-[35px] lg:pl-[30px] relative bg-[url('svg/box2_m.png')] lg:bg-[url('svg/box2.png')]  my-[50px] lg:m-5 w-full"
      >
        <div
          class="title absolute top-[-15px] lg:top-0 lg:left-9 left-0 lg:text-2xl xl:text-4xl text-xl font-bold text-white"
        >
          Embark on Your Journey
        </div>
        <div
          class="title-text absolute lg:left-9 left-0 lg:top-14 top-6 text-sm"
        >
          Make the AI experience smoother
        </div>
        <StepBar :steps="steps"></StepBar>
        <div id="explore" class="start absolute bottom-5 font-bold left-5 lg:left-9">
          Let's Get Started!
        </div>
      </div>
      <div  class="explore-models lg:w-full px-2 bg-[url('svg/box3_m.png')] lg:bg-none lg:px-0">
        <div
          class="models-header bg-none lg:bg-[url('svg/box3.png')] lg:h-72 h-40 relative mx-0 -mt-10 lg:mx-5"
        >
          <div
            class="models-title absolute lg:left-9 left-0 lg:top-24 top-10 lg:text-2xl xl:text-4xl text-xl font-bold text-white"
          >
            Web3 AI Oasis: Explore Models
          </div>
          <div
            class="models-text absolute lg:left-9 left-0 lg:top-40 top-20 text-sm"
          >
            Let's find the perfect AI buddy to bring your ideas to life!
          </div>
        </div>
        <div
          class="flex -mt-10 flex-row flex-wrap justify-center lg:justify-between  px-[0] lg:px-[20px]"
        >
          <AdCard
            v-for="item in cardList"
            :key="item.id"
            :card-obj="item"
            :score-list="scoreList"
          ></AdCard>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Part from '@/components/Part/Index'
import AdCard from '@/components/Card/AdCard.vue'
import StepBar from '@/components/StepBar/Index.vue'
import Footer from '@/components/Footer/index.vue'
import img1 from '@/assets/images/img1.png'
import img2 from '@/assets/images/img2.png'
import img3 from '@/assets/images/img3.png'
import explore1 from '@/assets/images/explore1.png'
import explore2 from '@/assets/images/explore2.png'
import explore3 from '@/assets/images/explore3.png'
import explore4 from '@/assets/images/explore4.png'
import explore5 from '@/assets/images/explore5.png'
import explore6 from '@/assets/images/explore6.png'

import { scoreList } from './../api'

export default {
  name: 'HomeView',
  components: {
    Header,
    Part,
    AdCard,
    StepBar,
    Footer
  },
  data () {
    return {
      scoreList: [],
      cardList: [
        {
          id: '1',
          sortIndex: 0,
          title: 'GPT-4',
          text: 'GPT-4 is your creative companion, ready to compose songs, craft screenplays, and even learn your unique writing style. Let your imagination soar with endless possibilities!',
          imgPath: explore1,
          router: '/chatgpt'
        },
        {
          id: '2',
          sortIndex: 1,
          title: 'Text-to-Image',
          text: 'Unlock the magic of our text-to-image model! Watch as it brings your words to life with stunning, lifelike images. Let your imagination run wild!',
          imgPath: explore2,
          router: '/text-to-image'
        },
        {
          id: '3',
          sortIndex: 2,
          title: 'Restoring old photos',
          text: 'Revive old photos with Blind Face Restoration and Vector-Quantized Dictionary. Improve facial features and restore faded images accurately. Using advanced algorithms and parallel decoding, cherish memories for the future.',
          imgPath: explore5,
          router: '/old-photos',
          query: { type: 'enhance' }
        },
        {
          id: '4',
          sortIndex: 3,
          title: 'Coloring old photos',
          text: 'By digitally coloring old photos, grayscale images are transformed using advanced technology, infusing them with authentic hues and vibrancy, revitalizing cherished memories and history',
          imgPath: explore6,
          router: '/old-photos',
          query: { type: 'colorizer' }
        },
        {
          id: '5',
          sortIndex: 4,
          title: 'Text-to-Video',
          text: 'Experience text-to-video magic! Our generation model transforms your words into captivating videos that match your descriptions. Explore storytelling like never before!',
          imgPath: explore3,
          isComingSoon: true
        },
        {
          id: '6',
          sortIndex: 5,
          title: 'Text-to-Audio',
          text: 'Meet AudioLDM: Your audio magician! Create sound effects, speech, and music with text. Experience style-transfer, inpainting, and super-resolution effortlessly!',
          imgPath: explore4,
          isComingSoon: true
        }

      ],
      steps: [
        {
          title: 'Connected with Web3 Wallet',
          text: 'Begin by connecting your Web3 wallet to access TARS AI Hub'
        },
        {
          title: 'Choose Your Desired AI Model',
          text: 'Browse through AI Models Library and click "Launch" on the one you\'d like to use'
        },
        {
          title: 'Wallet Balance Checked',
          text: 'The system automatically checks if your wallet meets the crypto holding requirement'
        },
        {
          title: 'Access Granted: Use AI Seamlessly',
          text: 'If balance insufficient, access denied. Buy&hold required crypto, and retry step 2'
        }
      ],
      partList: [
        {
          title: 'Scalable AI on Web3',
          text: 'Use AI products for free by simply connecting your wallet',
          url: img1
        },
        {
          title: 'AI-Powered Solutions',
          text: 'Aggregating the most advanced AI models',
          url: img2
        },
        {
          title: 'Innovative Integration',
          text: 'Web3 empowers AI, AI drives Web3',
          url: img3
        }
      ]
    }
  },
  created () {
    this.getScoreList()
  },
  mounted () {
    const hash = this.$route.hash
    if (hash) {
      const element = document.getElementById(hash.substring(1))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  },

  methods: {
    scrollToAnchorPoint () {
      const element = document.getElementById('explore')
      element.scrollIntoView({ behavior: 'smooth' })
    },
    // 获取积分列表
    async getScoreList () {
      try {
        const { code, data } = await scoreList()
        if (code === 0) {
          this.scoreList = data || []
        }
      } catch (error) {
        console.log('Error in getScoreList', error)
      }
    }
  }
}
</script>
<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
  background-color: black;
  .content {
    flex: 1;
    //margin: 70px auto;
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    flex-wrap: wrap;
    color: white;
    .journey {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      // padding: 80px 40px 35px 30px;
      position: relative;
      // .title {
      //   top: 0px;
      // }
    }
    .explore-models {
      background-repeat: no-repeat;
      background-size: 99% 100%;
      .models-header {
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .part-wrap {
      // background-image: url('@/assets/svg/box1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      // padding: 120px 40px 35px 30px;
    }
  }
  .home-bg {
    background-image: url("@/assets/images/home_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.rect {
    position: relative;
    color: #000;
    // width: 250px;
    background: #fff;
    font-weight: 700;
    padding: 15px 20px;
    cursor: pointer;
    // font-size: 24px;
    &::after {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 0px;
        height: 0px;
        border-width: 12px;
        border-style: solid;
        border-color: #080e0b #080e0b transparent transparent;
        border-image: initial;
        content: " ";
    }
}
</style>
