<template>
  <div class="part px-3 flex flex-col items-center lg:h-[17vw] h-[17vh]">
    <div class="pic mt-10">
      <img :src="data.url" alt="">
    </div>
    <div class="title text-white text-2xl text-center my-2 lg:my-5">{{ data.title }}</div>
    <div class="text text-center size text-sm">{{ data.text }}</div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="less">
.part {
  background: linear-gradient(to bottom, #222, #000);
  width: 100%;
  overflow: hidden;
  .pic {
    width: 80px;
    height: 80px;
    // background-color: aliceblue;
    margin-top: 20px auto;

  }
}
</style>