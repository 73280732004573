import Vue from 'vue'
import Vuex from 'vuex'
import { connect, disconnect } from 'starknetkit'
import starkNetService from './../utils/starkNetService'
import { login, userInfo, stakeInfo, tokenList, scoreBillList } from './../api'
import { addAddressPadding } from 'starknet'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    walletAddress: null,
    provider: null,
    connection: null,
    token: '',
    userInfo: {},

    stakeInfoList: [],
    tokenList: [],
    isUserReject: false,
    isLogined: false,
    tableData: {
      list: [],
      totalCount: 0
    }
  },
  mutations: {
    setConnection (state, wallet) {
      state.connection = wallet
    },
    setAddress (state, address) {
      state.walletAddress = address
    },
    setProvider (state, provider) {
      state.provider = provider
    },
    clearConnection (state) {
      state.connection = null
      state.provider = null
      state.walletAddress = null
    },
    setToken (state, token) {
      state.token = token
    },
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
    updateRejectStatus (state, reject) {
      state.isUserReject = reject
    },
    setStakeInfoList (state, list) {
      state.stakeInfoList = list
    },
    setTokenList (state, list) {
      state.tokenList = list
    },
    setLoginStatus (state, isLogined) {
      state.isLogined = isLogined
    },
    setTableData (state, tableData) {
      state.tableData = tableData
    }

  },
  actions: {
    // 连接钱包
    async connectWallet ({ commit, dispatch }, flag) {
      try {
        const { wallet } = await connect()
        if (wallet && wallet.isConnected) {
          commit('setConnection', wallet)
          commit('setAddress', addAddressPadding(wallet.selectedAddress))
          commit('setProvider', wallet.account)
          sessionStorage.setItem('wallet', JSON.stringify(wallet))
          sessionStorage.setItem('walletAddress', addAddressPadding(wallet.selectedAddress))
          if (!sessionStorage.getItem('isLogined') && !flag) {
            dispatch('userLogin')
          }
          dispatch('accountChangeListener', wallet)
        }
      } catch (error) {
        console.log(7777, error)
      }
    },
    // 断开钱包
    async disconnectWallet ({ commit }) {
      try {
        await disconnect({ clearLastWallet: true })
        commit('clearConnection', null)
        commit('setAddress', null)
        commit('setProvider', null)
        commit('setToken', '')
        commit('setUserInfo', {})
        commit('updateRejectStatus', false)
        commit('setTableData', { list: [], totalCount: 0 })
        commit('setLoginStatus', false)
        sessionStorage.removeItem('walletAddress')
        sessionStorage.removeItem('wallet')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('isUserReject')
        sessionStorage.removeItem('isLogined')
      } catch (error) {
        console.log(error)
      }
    },
    // 检查是否连接过钱包
    async checkWalletConnection ({ commit, dispatch }) {
      const walletAddress = sessionStorage.getItem('walletAddress')
      const token = sessionStorage.getItem('token')
      const isLogined = sessionStorage.getItem('isLogined') === 'true'
      const isUserReject = sessionStorage.removeItem('isUserReject') === 'true'

      if (walletAddress) {
        commit('setToken', token)
        commit('setLoginStatus', isLogined)
        commit('updateRejectStatus', isUserReject)
        dispatch('connectWallet', true)
        if (isLogined) {
          dispatch('getUserInfo')
          dispatch('getStakeInfo')
          dispatch('getTokenList')
        }
      }
    },
    // 监听账号切换
    accountChangeListener ({ state, commit, dispatch }, wallet) {
      if (!wallet) {
        return
      }
      wallet.on('accountsChanged', (account) => {
        if (account.length) {
          const newAddress = addAddressPadding(account[0])
          const oldAddress = state.walletAddress
          if (newAddress !== oldAddress) {
            sessionStorage.removeItem('token')
            commit('setToken', '')
            commit('setAddress', newAddress)
            dispatch('userLogin')
          }
        }
      })
    },
    // 用户登录
    async userLogin ({ state, dispatch, commit }) {
      try {
        const { signature, typedMessage } = await starkNetService.getSignature(state.provider, state.connection.chainId)
        const { code, data } = await login({ address: state.walletAddress, signature, typeData: typedMessage })
        if (code === 0) {
          sessionStorage.setItem('token', data.token)
          sessionStorage.setItem('isLogined', true)
          sessionStorage.removeItem('isUserReject')
          commit('updateRejectStatus', false)
          commit('setToken', data.token)
          commit('setLoginStatus', true)
          dispatch('getUserInfo')
          dispatch('getStakeInfo')
          dispatch('getTokenList')
        }
      } catch (error) {
        console.log('Error in userLogin', error)
        sessionStorage.setItem('isUserReject', true)
        commit('updateRejectStatus', true)
      }
    },
    // 获取用户信息
    async getUserInfo ({ commit }) {
      try {
        const { code, data } = await userInfo()
        if (code === 0) {
          commit('setUserInfo', data)
        }
      } catch (error) {
        console.log('Error in getUserInfo', error)
      }
    },
    // 获取质押信息
    async getStakeInfo ({ commit }) {
      try {
        const { code, data } = await stakeInfo()
        if (code === 0) {
          const list = (data || []).map(item => {
            return {
              ...item,
              stakeBalance: starkNetService.formatStakeAmount(item.stakeBalance, parseInt(item.tokenDecimals))
            }
          })
          commit('setStakeInfoList', list)
        }
      } catch (error) {
        console.log('Error in getStakeInfo', error)
      }
    },
    // 获取token列表
    async getTokenList ({ commit }) {
      try {
        const { code, data } = await tokenList()
        if (code === 0) {
          commit('setTokenList', data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getTableData ({ commit }, pagination) {
      try {
        const { code, data } = await scoreBillList(pagination)
        if (code === 0) {
          const totalCount = data.totalCount
          const list = (data.list || []).map(item => {
            return {
              ...item,
              inOrOut: item.inOrOut ? 'IN' : 'OUT'
            }
          })
          const tableData = {
            list,
            totalCount
          }
          commit('setTableData', tableData)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
})
