import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/chatgpt',
    name: 'chatgpt',
    meta: {
      isAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "ChatGpt" */ '../views/ChatGPT.vue')
  },
  {
    path: '/old-photos',
    name: 'old-photos',
    meta: {
      isAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "OldPhotos" */ '../views/OldPhotos.vue')
  },
  {
    path: '/text-to-image',
    name: 'textToImage',
    meta: {
      isAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "TextToImage" */ '../views/TextToImage.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    meta: {
      isAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "Detail" */ '../views/Detail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由拦截
router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token')
  const isAuth = to.matched.some(item => item.meta.isAuth)
  if (isAuth && !token) {
    next('/')
  } else {
    next()
  }
})

export default router
