<template>
  <div class="step-box flex">
    <div class="mt-7 rounded-2xl lg:p-10 p-2">
      <div class="grid lg:grid-cols-4">
        <div class="flex lg:block" v-for="(item, index) in steps" :key="index">
          <div class="flex flex-col items-center justify-center lg:flex-row lg:justify-start">
            <div class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-primary text-2xl font-medium text-black bg-white">{{ index + 1 }}</div>
            <div class="flex-1 border-l-2 border-dashed border-white/75 lg:border-l-0 lg:border-b-2 ml-3 mr-3"></div>
          </div>
          <div class="pl-2 pb-9 lg:pb-0 lg:pr-20 lg:pl-1">
            <div class="mt-2 font-semibold text-primary lg:mt-6">{{ item.title }}</div>
            <p class="mt-3 text-sm leading-relaxed text-[#fafcfa] lg:text-xs">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="less">
.step-box {
  width: 100%;
  background-color: black;
  color: #fff;
  .step-index {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    color: black;
    font-size: 20;
    font-weight: bold;
    text-align: center;
    line-height: 40px;

  }
}
</style>
