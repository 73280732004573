<template>
  <div>
    <div class="header px-[0%] lg:px-[10%] w-full">
      <div class="content">
        <div class="logo">
          <a href="/"><img src="./../../assets/logo.svg" width="100" alt="logo"></a>
        </div>
        <div class="hidden md:block">
          <ul class="menu-list space-x-8">
            <li><a href="/" class="hover:font-bold">Home</a></li>
            <li @click="scrollToPoint"><a class="hover:font-bold" style="cursor: pointer;">Explore</a></li>
            <li @click="showNotice"><a href="#" class="hover:font-bold">AI Community</a></li>
            <li @click="showNotice"><a href="#" class="hover:font-bold">Docs</a></li>
            <li class="rect"  @click="connectWallet(false)" v-if="!walletAddress || !isLogined">{{ isUserReject ? 'Login' : 'Connect Wallet'}} </li>
            <li class="rect active" v-else @click="toggleVisible(false)" ref="button">{{formatAddress(walletAddress,4,6)}}</li>
          </ul>
        </div>
        <div class="md:hidden">
          <button id="menu-toggle" class="text-white focus:outline-none" @click="menuToggle">
            <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="toggleStatus ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div id="mobile-menu" class="md:hidden bg-black" :class="[toggleStatus ? '' : 'hidden']">
      <ul class="menu">
        <li><a href="#">Home</a></li>
        <li @click="scrollToPoint"><a>Explore</a></li>
        <li @click="showNotice"><a>AI Community</a></li>
        <li><a href="#">Docs</a></li>
        <li style="height:86px">
            <div class="rect mx-auto"  @click="connectWallet(false)" v-if="!walletAddress || !isLogined">{{ isUserReject ? 'Login' : 'Connect Wallet'}}</div>
            <div class="rect active mx-auto" v-else @click="toggleVisible(true)" ref="mobileBtn">{{formatAddress(walletAddress,4,6)}}</div>
        </li>
      </ul>
    </div>
    <div class="modal lg:top-[80px] lg:left-auto lg:right-[8%] lg:w-[276px] top-[20%] w-[276px] left-[50%]  lg:translate-x-[0%] translate-x-[-50%]"  ref="modal" v-show="visible" >
      <div class="item">
        <div class="line-clamp-2">{{formatAddress(walletAddress,10,12)}}</div>
        <div class="flex justify-center">
          <div class="rect  active small" style="width:120px" @click="disconnect">Disscount</div>
        </div>
      </div>
      <div class="item">
          <div class="flex items-center justify-between mb-[15px]"  v-for="(item,index) in stakeInfoList" :key="index">
            <span class="rgba-white-80 flex items-center">
              <img :src="item.tokenLogo" width="15">
              <span class="mx-[5px]">{{item.tokenSymbol}}</span>
              <span>Staked</span>
            </span>
             <span class="font-bold">{{item.stakeBalance}}</span>
          </div>
          <div class="flex justify-center">
            <div class="rect small" @click="handleStakeBtnClick">Stake</div>
          </div>
      </div>
      <div class="item">
          <div class="flex items-center justify-between mb-2" >
            <span class="rgba-white-80">Score</span>
             <span class="font-bold">{{userInfo.score}}</span>
          </div>
          <div class="flex justify-center">
            <div class="rect small" @click="toDetailPage">Details</div>
          </div>
      </div>
    </div>
    <div class="layer" v-show="visible"></div>

    <Dialog ref="dialog"></Dialog>
  </div>
</template>

<script>
import starkNetService from './../../utils/starkNetService'
import { mapState, mapActions } from 'vuex'
import Dialog from './../Dialog'

export default {
  components: {
    Dialog
  },
  data () {
    return {
      account: null,
      connected: false,
      toggleStatus: false,
      visible: false,
      isMobile: false,
      stakeAmount: ''
    }
  },
  mounted () {
    setTimeout(() => {
      this.checkWalletConnection()
    }, 500)
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  computed: {
    ...mapState(['walletAddress', 'provider', 'connection', 'isLogined', 'isUserReject', 'userInfo', 'stakeInfoList', 'tokenList']),

    formatStakeAmount () {
      return starkNetService.formatStakeAmount(this.stakeAmount)
    }
  },
  methods: {
    handleStakeBtnClick () {
      if (this.$refs.modal) {
        this.$refs.dialog.showModal()
        this.visible = false
      }
    },

    disconnect () {
      this.disconnectWallet()
      this.visible = false
    },
    ...mapActions(['connectWallet', 'disconnectWallet', 'checkWalletConnection', 'getUserInfo']),
    menuToggle () {
      this.toggleStatus = !this.toggleStatus
    },
    scrollToPoint () {
      if (location.pathname !== '/') {
        this.$router.push({
          path: '/#explore'
        })
      } else {
        this.$emit('scrollTo')
      }
      this.toggleStatus = false
    },
    toggleVisible (isMobile) {
      this.visible = !this.visible
      this.isMobile = isMobile
      if (isMobile) {
        this.toggleStatus = false
      }
      if (this.visible) {
        this.getUserInfo()
      }
    },
    handleClickOutside (event) {
      if (!this.$refs.modal.contains(event.target) && this.$refs.button && !this.$refs.button.contains(event.target) && this.$refs.mobileBtn && !this.$refs.mobileBtn.contains(event.target)) {
        this.visible = false
      }
    },
    formatAddress (str, startNum, endNum) {
      if (!str) {
        return
      }
      const start = str.slice(0, startNum)
      const end = str.slice(-endNum)
      return start + '...' + end
    },
    toDetailPage () {
      if (location.pathname !== '/detail') {
        this.$router.push({
          path: '/detail'
        })
      }
    },
    showNotice () {
      this.$message({
        message: 'Coming Soon!',
        offset: 100
      })
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  height: 72px;
  background: #000;
  z-index: 9;
  .content {
     height: 100%;
     color: #fff;
     padding: 0 20px;
     display: flex;
     justify-content: space-between;
     align-items: center;
    .menu-list {
      display: flex;
      align-items: center;
    }
  }
}

#mobile-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 72px;
  z-index: 99;
  .menu {
    display: flex;
    flex-direction: column;
    li {
      padding: 0 20px;
      display: flex;
      align-items: center;
      height: 64px;
      color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      // &:last-child{
      //   justify-content: center;
      //   height: 86px;
      //   span{
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     width:170px;
      //     height: 48px;
      //     font-weight: 700;
      //     border-radius: 10px;
      //     color: #000;
      //     background: #fff;
      //     cursor: pointer;
      //   }
      // }
    }
  }
}

.rect {
    position: relative;
    color: #000;
    background: #fff;
    font-weight: 700;
    padding: 8px 0;
    width: 150px;
    text-align: center;
    cursor: pointer;
    &::after {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 0px;
      height: 0px;
      border-width: 8px;
      border-style: solid;
      border-color: #080e0b #080e0b transparent transparent;
      border-image: initial;
      content: " ";
    }
    &.active{
      color: #fff;
      border: 1px solid #fff;
      background: #000;
       &::after {
          border-color: #fff #fff transparent transparent;
       }
       &::before{
          position: absolute;
          top: -1px;
          right: -1px;
          width: 0px;
          height: 0px;
          border-width: 8px;
          border-style: solid;
          border-color: #080e0b #080e0b transparent transparent;
          border-image: initial;
          content: " ";
          z-index: 1000;
       }
    }

    &.small{
      padding: 5px 0;
      font-size: 14px;
      // width: 100px;
       &::after {
          border-width: 4px;
       }
        &::before{
          border-width: 4px;
        }
    }
  }

  .modal{
    position: fixed;
    // top: 80px;
    // right: 7%;
    // width: 276px;
    // height: 375px;
    color: #fff;
    background: #1A1A1A;
    overflow: hidden;
    z-index: 999;
    padding:  0 15px;
    &.mobile{
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 90%;

    }
    .item{
      font-size: 14px;
      border-bottom:1px solid #343434;
      padding: 20px 0;
      &:last-child{
        border-bottom: none;
      }
      .rgba-white-80{
        color: rgba(255,255,255,0.8);
      }
    }
    .line-clamp-2 {
      padding: 15px;
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
      text-align: center;
      font-weight: 700;
    }
  }

  .layer{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 888;
    overflow: hidden;
  }
</style>
