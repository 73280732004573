<template>
  <div class="dialog" v-if="show">
    <div class="dialog-wrap lg:w-[600px] lg:h-[388px] w-[414px] h-[350px] lg:bg-[url('images/dialog_bg.png')] bg-none lg:py-[20px] lg:pl-[40px] lg:pr-[20px] p-[15px]">
      <div class="dialog-close" @click="hiddenModal"><i class="el-icon-close font-bold"></i></div>
      <div class="dialog-content lg:pb-[25px] lg:px-[25px] lg:pt-30px pb-[15px] px-[15px]">
          <div class="tab-wrap">
            <div class="tab">
               <div class="tab-item" v-for="item in tabList" :key="item.name"  :class="[currentTab === item.value ? 'active' : '']" @click="handleTabChange(item)">{{item.name}}</div>
            </div>
          </div>

          <div class="input-wrap flex items-center" v-if="currentTab">
            <div class="lg:w-[150px] w-[120px]">
            <el-select v-model="stakeSelectedValue" >
               <template slot="prefix">
                <img v-if="stakeSelectedValue" :src="selectedIcon"   width="20" height="20"/>
              </template>
               <el-option
                v-for="item in tokenList"
                :key="item.value"
                :label="item.tokenSymbol"
                :value="item.address">
                  <div class="flex items-center">
                    <img :src="item.tokenLogo"  width="20" height="20" class="mr-[15px]">
                    <span>{{item.tokenSymbol}}</span>
                  </div>
              </el-option>
            </el-select>
            </div>
           <div class="flex-1">
              <el-input :value="stakeInputValue" @input="handleInput"></el-input>
           </div>
              <div class="max-btn lg:w-[40px] w-[30px]" @click="handleMaxBtnClick">
                 <img src="./../../assets/svg/MAX.svg" width="100%">
              </div>
              <div class="rect lg:w-[120px] w-[100px] capitalize " :class="[(stakeInputValue > 0 && !loading)? '' : 'opacity-50']" @click="handleStake"><i class="el-icon-loading" v-show="loading"></i> Stake</div>
          </div>

          <div class="input-wrap flex items-center" v-else>
            <div class="lg:w-[150px] w-[120px]">
            <el-select v-model="unStakeSelectedValue" >
               <template slot="prefix">
                <img v-if="unStakeSelectedValue" :src="selectedIcon"  width="20" height="20" />
              </template>
              <el-option
                v-for="item in tokenList"
                :key="item.value"
                :label="item.tokenSymbol"
                :value="item.address">
                  <div class="flex items-center">
                    <img :src="item.tokenLogo"  width="20" height="20"  class="mr-[15px]">
                    <span>{{item.tokenSymbol}}</span>
                  </div>
              </el-option>
            </el-select>
            </div>
           <div class="flex-1">
              <el-input v-model="unStakeInputValue"></el-input>
           </div>
              <div class="max-btn lg:w-[40px] w-[30px]" @click="handleMaxBtnClick">
                 <img src="./../../assets/svg/MAX.svg" width="100%">
              </div>
              <div class="rect lg:w-[120px] w-[100px] capitalize"  :class="[(unStakeInputValue > 0 && !loading)? '' : 'opacity-50']" @click="handleWithdraw"><i class="el-icon-loading" v-show="loading"></i>UnStake</div>
          </div>

          <div class="text-white mt-[30px] lg:text-1xl font-bold">Text Description</div>
          <div class="mt-[15px]" v-if="currentTab">
            <div class="desc">1. It can be withdrawn at any time after pledge</div>
            <div class="desc">2. Take a snapshot of the amount pledged every 30 minutes and calculate the points</div>
            <div class="desc">3. Calculation rule: xxxxxxxxxxxxxxxxxxxxxxx</div>
          </div>
          <div class="mt-[15px]" v-else>
            <div class="desc">1. Partial decompression is supported</div>
            <div class="desc">2. If it is partially decompressed, the remaining tokens pledged in the contract will still calculate the points</div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import starkNetService from './../../utils/starkNetService'
import { mapState } from 'vuex'
// import icon1 from './../../assets/svg/select_icon1.svg'
// import icon2 from './../../assets/svg/select_icon2.svg'
// import icon3 from './../../assets/svg/select_icon3.svg'
import icon4 from './../../assets/svg/select_icon4.svg'
export default {
  data () {
    return {
      show: false,
      loading: false,
      currentTab: 1,
      stakeDecimals: '',
      unStakeDecimals: '',
      stakeInputValue: '',
      stakeSelectedValue: '',
      unStakeInputValue: '',
      unStakeSelectedValue: '',
      tabList: [
        {
          name: 'Stake',
          value: 1
        },
        {
          name: 'UnStake',
          value: 0
        }
      ],
      selectList: [
        {
          label: 'STARK',
          value: 'STARK',
          icon: icon4
        }
        // {
        //   label: 'SOL',
        //   value: 'SOL',
        //   icon: icon1
        // },
        // {
        //   label: 'SOL-USDC',
        //   value: 'SOL-USDC',
        //   icon: icon1
        // },
        // {
        //   label: 'ETH',
        //   value: 'ETH',
        //   icon: icon2
        // },
        // {
        //   label: 'ETH-USDT',
        //   value: 'ETH-USDT',
        //   icon: icon2
        // },
        // {
        //   label: 'BNB',
        //   value: 'BNB',
        //   icon: icon3
        // },
        // {
        //   label: 'BSC-USDT',
        //   value: 'BSC-USDT',
        //   icon: icon3
        // }
      ]
    }
  },
  mounted () {

  },
  methods: {
    showModal () {
      this.show = true
    },
    hiddenModal () {
      this.show = false
    },
    disableScroll () {
      document.body.style.overflow = 'hidden'
    },
    enableScroll () {
      document.body.style.overflow = ''
    },
    handleTabChange (item) {
      this.currentTab = item.value
    },
    handleMaxBtnClick () {
      if (this.currentTab) {
        this.stakeInputValue = this.balance
      } else {
        this.unStakeInputValue = this.stakeAmount
      }
    },
    getBalance () {
      const tokenAddress = this.stakeSelectedValue
      starkNetService.getBalance(this.provider, tokenAddress).then(res => {
        this.balance = starkNetService.formatStakeAmount(res, this.stakeDecimals)
      })
    },
    getStakeAmount () {
      const tokenAddress = this.unStakeSelectedValue
      starkNetService.getStakeAmount(this.walletAddress, tokenAddress).then(amount => {
        this.stakeAmount = starkNetService.formatStakeAmount(amount, this.unStakeDecimals)
      })
    },
    handleStake () {
      if (this.stakeInputValue <= 0) {
        return
      }
      const tokenAddress = this.stakeSelectedValue
      const stakeAmount = starkNetService.restoreStakeAmount(this.stakeInputValue)
      this.loading = true
      starkNetService.stakeTokens(this.provider, stakeAmount, tokenAddress).then(amount => {
        if (amount) {
          this.show = false
          this.$message({
            message: 'Success!',
            offset: 100,
            type: 'success'
          })
        }
        this.loading = false
      }).catch(() => {
        this.$message({
          message: 'Failed!',
          offset: 100,
          type: 'error'
        })
        this.loading = false
      })
    },
    handleWithdraw () {
      if (this.unStakeInputValue <= 0) {
        return
      }
      const tokenAddress = this.unStakeSelectedValue
      const stakeAmount = starkNetService.restoreStakeAmount(this.unStakeInputValue)
      this.loading = true
      starkNetService.withdrawTokens(this.provider, stakeAmount, tokenAddress).then(amount => {
        if (amount) {
          this.show = false
          this.$message({
            message: 'Success!',
            offset: 100,
            type: 'success'
          })
        }
        this.loading = false
      }).catch(() => {
        this.$message({
          message: 'Failed!',
          offset: 100,
          type: 'error'
        })
        this.loading = false
        console.log(7777)
      })
    },
    handleInput (value) {
      const regex = /^\d*\.?\d{0,4}$/
      if (regex.test(value)) {
        if (this.currentTab) {
          this.stakeInputValue = value
        } else {
          this.unStakeInputValue = value
        }
      }
    },
    setSelectedValue () {
      // 设置tekenList第一个为默认值
      if (this.tokenList.length) {
        const firstItem = this.tokenList[0]
        this.stakeSelectedValue = firstItem.address
        this.unStakeSelectedValue = firstItem.address
        this.stakeDecimals = firstItem.tokenDecimals
        this.unStakeDecimals = firstItem.tokenDecimals
        this.getBalance()
        this.getStakeAmount()
      }
    }
  },
  computed: {
    ...mapState(['walletAddress', 'provider', 'connection', 'tokenList']),

    selectedIcon () {
      const selectedItem = this.tokenList.find(item => item.address === (this.currentTab ? this.stakeSelectedValue : this.unStakeSelectedValue))
      return selectedItem ? selectedItem.tokenLogo : ''
    }
  },
  watch: {
    show (isShow) {
      if (isShow) {
        this.disableScroll()
        this.setSelectedValue()
      } else {
        this.enableScroll()
        this.currentTab = 1
        this.stakeInputValue = ''
        this.unStakeInputValue = ''
        this.stakeSelectedValue = ''
        this.unStakeSelectedValue = ''
        this.stakeDecimals = ''
        this.unStakeDecimals = ''
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.dialog{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .dialog-wrap{
    position: relative;
    background-size: cover;
    box-sizing: border-box;
    .dialog-close{
      position: absolute;
      top: -7px;
      right: -8px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }
    .dialog-content{
      width: 100%;
      height: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background: linear-gradient(#191919, #000);
      .tab-wrap{
        padding: 30px 0;
        .tab{
          display: flex;
          align-items: center;
          width: 200px;
          margin: auto;
          color: #fff;
          border:1px solid #fff;
          border-radius: 5px;
          overflow: hidden;
          .tab-item{
            flex: 1;
            text-align: center;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            cursor: pointer;
            &.active{
              background: #fff;
              color: #000;
              font-weight: 700;
            }
            &:first-child{
              border-right: 1px solid #fff;
            }
          }
        }
      }
      .input-wrap{
        background: #000;
      }
      .max-btn{
        margin-right: 20px;
        cursor: pointer;
        padding: 10px 0;
        box-sizing: border-box;
      }
      .desc{
        margin-bottom: 5px;
        font-size: 12px;
        color: rgba(255,255,255,0.8);
      }
    }
  }
}

.rect {
    position: relative;
    color: #000;
    background: #fff;
    font-weight: 700;
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
    &::after {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 0px;
      height: 0px;
      border-width: 8px;
      border-style: solid;
      border-color: #080e0b #080e0b transparent transparent;
      border-image: initial;
      content: " ";
    }
  }

</style>
